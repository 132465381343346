@import './fonts.scss';
@import './variables.scss';

.App {
    text-align: center;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    background: linear-gradient(180deg, #f5f6fb 0%, #f5f6fb 100%);
    display: flex;
    flex-direction: row;
    color: #343951;
    top: 0;
    left: 0;
    position: fixed;

    .content {
        display: flex;
        flex-direction: column;
        background: linear-gradient(180deg, #f5f6fb 0%, #f5f6fb 100%);
        flex: 1;
        height: 100%;
        padding: 40px;
        overflow-y: auto;
        @media screen and (max-width: 786px) {
            padding: 24px;
        }
    }
}

.roundedBoxWithShadow {
    background: #ffffff;
    border-radius: 16px;
    box-shadow: 0px 2px 12px rgba(39, 43, 58, 0.07);
}

.bigSpace {
    height: 48px;
}

.mediumSpace {
    height: 24px;
}

.stretchSpacer {
    flex: 1;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
