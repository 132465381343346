body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #343951;
    height: 100%;
    width: 100%;
    overflow: auto;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
    letter-spacing: 0.04em;
    text-transform: uppercase;
}

h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 21px;
    margin-top: 0;
    margin-bottom: 18px;

    @media screen and (max-width: 786px) {
        font-size: 18px;
        margin-bottom: 12px;
    }
}
