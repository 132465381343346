.functionalComponentRoot {
    padding: 28px 0px;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    overflow-y: hidden;

    .connectedItemsContainer {
        margin-top: 18px;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        flex-shrink: 1;
        padding-bottom: 28px;
        align-self: stretch;
    }
}
