.connectedItemRoot {
    display: flex;
    min-width: 200px;
    text-align: left;
    width: 499px;
    padding: 28px;
    margin-top: 28px;

    flex-direction: column;

    .title {
        flex: 1;
        text-align: left;
        align-self: flex-start;
        margin-bottom: 0px;
    }

    .component {
      display: flex;
      margin-top: 16px;
    }

    .valueTitle {
      text-align: left;
      align-self: flex-start;
      margin-right: 12px;
      font-weight: 500;

      &:after {
        content: ": "
      }
    }

    .value {
        flex: 1;
        text-align: left;
        align-self: center;
    }

    &.offline {
        background: repeating-linear-gradient(
            45deg,
            #cecece,
            #cecece 10px,
            #ececec 10px,
            #ececec 20px
        );
    }
}
