.loginPageRoot {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #e2ecfe;
    display: flex;
    align-items: center;
    justify-content: center;

    .innerLoginContainer {
        min-width: 897px;
        min-height: 575px;
        background-color: #e2ecfe;

        border-radius: 16px;
        box-shadow: 0px 15px 80px -5px rgba(0, 0, 0, 0.3);
        z-index: 20;
        position: relative;
        overflow: hidden;

        .dashboardImage {
            position: absolute;
            left: 22px;
            bottom: 85px;
            width: 451px;
            height: 347px;
        }

        .vectorClouds {
            position: absolute;
            left: -134px;
            top: -91px;
            width: 657px;
            height: 375px;
        }

        .loginHalf {
            padding-top: 126px;
            width: 50%;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: #fff;

            .loginFormContainer {
                padding-left: 88px;
                padding-top: 44px;
                width: 270px;
            }
        }
    }

    .backgroundHalf {
        width: 50%;
        position: fixed;
        right: 0;
        top: 0;
        bottom: 0;
        background: linear-gradient(180deg, #4c4c78 0%, rgba(76, 76, 120, 0) 100%);
        opacity: 0.2;
    }

    .wavesBackgroundImage {
        position: fixed;
        left: 0;
        width: 100vw;
        bottom: 0;
        opacity: 0.5;
    }
}
