.SidebarRoot {
    min-width: 176px;
    height: 100%;
    background: #fdfdfd;
    display: flex;
    position: relative;
    padding: 32px 0px 0px 28px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &:after {
        position: absolute;
        top: 0;
        right: -2px;
        height: 100%;
        width: 2px;
        background: linear-gradient(180deg, #d5daed 0%, #d4d9ec 100%);
        content: '';
    }

    // .headerLogoContainer {
    //     padding: 32px 28px 0px 28px;
    // }
}

.sidebarIconRoot {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    cursor: pointer;
    border-radius: 7px;
    transition-duration: 250ms;
    background-color: rgba($color: #e7effc, $alpha: 0);
    padding: 8px 8px 8px 12px;
    box-sizing: border-box;
    height: 36px;
    display: flex;
    min-width: 144px;
    overflow: hidden;
    transform: translateX(-12px);

    &:hover {
        background-color: rgba($color: #e7effc, $alpha: 1);
    }

    .iconContainer {
        margin-right: 14px;
        height: 36px;
        display: flex;
        align-items: center;
    }
}
