@import '../../variables.scss';

button {
    background-color: $color-purplish;
    border-radius: 4px;
    width: 100%;
    height: 34px;
    color: #fff;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    margin: 0px;
    box-sizing: border-box;
    border: none;
    outline: none;
    transition-duration: 150ms;
    cursor: pointer;

    &:hover {
        background-color: $color-purplish-hover;
    }

    &:active {
        background-color: $color-purplish-active;
    }
}
