@import '../../variables.scss';

input {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    border: none;
    border-bottom: solid 1px #babad3;
    outline: none;
    width: 100%;
    height: 34px;
    color: $color-purplish;
    box-sizing: border-box;
}

::-webkit-input-placeholder {
    color: $color-purplish-extra-bright;
}
