.dashboardPageRoot {
    padding: 28px 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    min-width: 846px;

    .topItems {
        display: flex;
        flex-direction: row;
        width: 100%;
    }
    .pageContent {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        flex-shrink: 1;
        overflow: hidden;
    }
}

.topItem {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: -30px;
    width: 100%;
    flex: 0 0 190px;

    .backgroundImage {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 135px;
    }

    .dashboardImage {
        position: absolute;
        right: 137px;
        bottom: 19px;
    }

    .topItemContent {
        position: absolute;
        bottom: 31px;
        left: 24px;
        width: 435px;
        display: flex;
        text-align: left;

        flex-direction: column;

        .contentText {
            font-size: 14px;
            line-height: 24px;
        }

        .contentHeader {
            font-size: 16px;
            line-height: 18px;
            font-weight: 500;
            display: flex;
            align-items: center;
            letter-spacing: 0.02em;
            margin-bottom: 18px;
            color: #07398d;
        }
    }
}
