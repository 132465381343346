/* rubik-300 - latin */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    src: local('Rubik Light'), local('Rubik-Light'), url('assets/fonts/rubik-v9-latin-300.woff2') format('woff2'),
        url('assets/fonts/rubik-v9-latin-300.woff') format('woff');
}
/* rubik-regular - latin */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    src: local('Rubik'), local('Rubik-Regular'), url('assets/fonts/rubik-v9-latin-regular.woff2') format('woff2'),
        url('assets/fonts/rubik-v9-latin-regular.woff') format('woff');
}
/* rubik-500 - latin */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    src: local('Rubik Medium'), local('Rubik-Medium'), url('assets/fonts/rubik-v9-latin-500.woff2') format('woff2'),
        url('assets/fonts/rubik-v9-latin-500.woff') format('woff');
}
/* rubik-700 - latin */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    src: local('Rubik Bold'), local('Rubik-Bold'), url('assets/fonts/rubik-v9-latin-700.woff2') format('woff2'),
        url('assets/fonts/rubik-v9-latin-700.woff') format('woff');
}
/* rubik-900 - latin */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 900;
    src: local('Rubik Black'), local('Rubik-Black'), url('assets/fonts/rubik-v9-latin-900.woff2') format('woff2'),
        url('assets/fonts/rubik-v9-latin-900.woff') format('woff');
}

* {
    font-family: 'Rubik';
}

h1 {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 24px;
    /* or 68% */

    text-align: center;

    /* Base / Dark Grey */

    color: #343951;
}
